import { defineStore } from 'pinia'
import { useStorage } from '@vueuse/core'
import { ref, computed } from 'vue'
// import SecureLS from 'secure-ls'

// const ls = new SecureLS({ encodingType: 'aes', isCompression: false, encryptionSecret: 'vcy6u05eq7Vi+MKssWx+Ly0Tm5lHAwjmwXTHDnMU/bI=' })
// const ls = new SecureLS({ isCompression: false })
export const stores = defineStore('appmodulos', () => {
  const colores = ref({})
  const rules = ref(null)
  const user = ref({})
  const token = ref({ id: 0, text: '' })
  const apiUrl = ref('')
  const auth = ref(false)
  const expira = ref(null)
  const prefix = ref('')
  const theme = ref(1)
  const items = ref(null)
  const dark = ref(false)
  const state = useStorage('appmodulos_store', { colores: colores.value, rules: rules.value, user: user.value, token: token.value, apiUrl: apiUrl.value, auth: auth.value, expira: expira.value, prefix: prefix.value, dark: dark.value, theme: theme.value, items: items.value })

  // getters
  const getColores = computed(() => state.value.colores)
  const getRules = computed(() => state.value.rules)
  const getUser = computed(() => {
    if (state.value.user != null) {
      return state.value.user
    } else {
      return ''
    }
  })
  const getToken = computed(() => state.value.token)
  const getUrlApi = computed(() => state.value.apiUrl + 'api/')
  const getStorage = computed(() => state.value.apiUrl + 'storage/')

  const getAuth = computed(() => state.value.auth)
  const getExpira = computed(() => state.value.expira)
  const getPrefix = computed(() => state.value.prefix)
  const getDark = computed(() => state.value.dark)
  const getTheme = computed(() => state.value.theme)
  const getItems = computed(() => state.value.items)

  const setColores = (pValue) => {
    state.value.colores = pValue
  }

  const setRules = (pValue) => {
    state.value.rules = pValue
  }

  const setUser = (pValue) => {
    state.value.user = pValue
  }

  const setToken = (pValue) => {
    state.value.token.id = pValue.accessToken.id
    state.value.token.text = pValue.plainTextToken
  }

  const setUrlApi = (pValue) => {
    state.value.apiUrl = pValue
  }

  const setPrefix = (pValue) => {
    state.value.prefix = pValue
  }

  const setItems = (pValue) => {
    state.value.items = pValue
  }

  const setTheme = (pValue) => {
    state.value.theme = pValue
  }

  const setDark = (pValue) => {
    state.value.dark = pValue
  }

  return { user, theme, colores, getColores, getRules, getUser, getToken, getUrlApi, getStorage, getAuth, getExpira, getPrefix, getDark, getTheme, getItems, setUser, setToken, setUrlApi, setColores, setPrefix, setTheme, setRules, setItems, setDark }
})

/*
export const stores = defineStore({
  id: 'crm_store',
  state: () => (
    useStorage('appmodulos', {
      colores: null,
      rules: null,
      user: null,
      token: { id: 0, text: '' },
      apiUrl: process.env.VUE_APP_INI,
      prefix: process.env.VUE_APP_PREFIX,
      auth: false,
      expira: null,
      items: null
    })
  ),
  getters: {
    getColores: (state) => state.colores,
    getRules: (state) => state.rules,
    getUser: (state) => state.user,
    getToken: (state) => state.token,
    getUrlApi: (state) => state.apiUrl + 'api/',
    getStorage: (state) => state.apiUrl + 'storage/',
    getAuth: (state) => state.auth,
    getExpira: (state) => state.expira,
    getPrefix: (state) => {
      if (state.user != null) {
        return state.user.rol.prefix
      } else {
        return ''
      }
    },
    getItems: (state) => state.items
  },
  actions: {
    setUrlApi (url) {
      this.apiUrl = url
    },
    setPrefix (prefix) {
      this.prefix = prefix
    },
    setColores (colores) {
      this.colores = colores
    },
    setRules (rules) {
      this.rules = rules
    },
    setUser (user) {
      this.user = user
      // ls.get('user'), this.user = ls.set('user', user)
    },
    setToken (token) {
      this.token.id = token.accessToken.id
      this.token.text = token.plainTextToken
    },
    setAuth (pAuth) {
      this.auth = pAuth
    },
    setExpira (pExpira) {
      this.expira = pExpira
    },
    setItems (pItems) {
      this.items = pItems
    }
    // createPersistedState

  }
})
*/
